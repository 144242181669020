jQuery(document).ready(function () {
  $(document).ready(function () {
    var navListItems = $('div.setup-panel div a'),
      allWells = $('.setup-content'),
      allNextBtn = $('.nextBtn'),
      allPrevBtn = $('.prevBtn');

    allWells.hide();

    navListItems.click(function (e) {
      e.preventDefault();
      var $target = $($(this).attr('href')),
        $item = $(this);

      if (!$item.hasClass('disabled')) {
        navListItems.removeClass('active-main').addClass('btn-default');
        $item.addClass('active-main');
        allWells.hide();
        $target.show();
        $target.find('input:eq(0)').focus();
      }
    });

    allPrevBtn.click(function () {
      var curStep = $(this).closest(".setup-content"),
        curStepBtn = curStep.attr("id"),
        prevStepSteps = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().prev().children("a");

      prevStepSteps.removeAttr('disabled').trigger('click');
    });

    allNextBtn.click(function () {
      var curStep = $(this).closest(".setup-content"),
        curStepBtn = curStep.attr("id"),
        nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
        curInputs = curStep.find("input[type='text'],input[type='url']"),
        isValid = true;

      $(".form-group").removeClass("has-error");
      // for(var i=0; i< curInputs.length; i++){
      //     if (!curInputs[i].validity.valid){
      //         isValid = false;
      //         $(curInputs[i]).closest(".form-group").addClass("has-error");
      //     }
      // }

      if (isValid)
        nextStepWizard.removeAttr('disabled').trigger('click');
    });

    $('div.setup-panel div a.active-main').trigger('click');


    // for dropdown menu

    // Get the button, and when the user clicks on it, execute myFunction
    if (document.getElementById("myBtn") !== null) {
      document.getElementById("myBtn").onclick = function () { myFunction() };
    }

    /* myFunction toggles between adding and removing the show class, which is used to hide and show the dropdown content */
    function myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
    }

    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {

      if (!event.target.matches('.dropbtn')) {

        var dropdowns = document.getElementsByClassName("dropdown-content");

        for (let i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }

    $(".selected-lang").click(function () {
      $(".lang-switch").toggleClass("open-lang-option");
    });

    $(".selected-company").click(function () {
      $(".company-switch").toggleClass("open-company-option");
    });


    // data tooltip code start

    function tooltiparrow() {
      if (jQuery(window).width() < 768) {
        $('[data-toggle="tooltip"]').tooltip({
          placement: 'top'
        });
      }
      else {
        $('[data-toggle="tooltip"]').tooltip({
          placement: 'right'
        });
      }
    }

    //(For resize window function);
    tooltiparrow();
    jQuery(window).resize(tooltiparrow);

    // data tooltip code start


  });
});
